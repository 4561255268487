import React from "react";

import SiteStructure from "../components/siteStructure/SiteStructure";
import { IconContext } from "react-icons";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import SEO from "../components/SEO/Seo";

import "./contact.scss";

const Map = () => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13401.633341460452!2d-68.85951991637174!3d-32.88737054501923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x967e0912403f8fbb%3A0x33a1075b1c682cb0!2sBlue%20Sky%20SA!5e0!3m2!1ses!2sar!4v1612828600805!5m2!1ses!2sar"
    height="450"
    frameBorder="0"
    allowFullScreen
    aria-hidden="false"
    styles={{ border: 0 }}
    className="google-map pr-2"
    title="bluesky-map"
  ></iframe>
);
export default function Contact() {
  // if (typeof window !== "undefined" && window.screen.width < 1000) {
  //   window.location = "http://www.blueskysa.com.ar/movile/03-contactos.html";
  // }

  return (
    <>
      <SEO
        title="Blue Sky SA - Botellas de Vidrio. Contactenos"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio ,Nosotros, Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  - Botellas de vidrio Blue Sky SA - Botellas de vidrio . Espumantes. Champagne, champañeras o cava. Alta, baja, con picada, sin picada. 750 ml. Color verde, ambar, marrón, blanco y verde oscuro. Boca corcho y corona. Mendoza - Argentina."
        description=" Contactenos para obtener mas informacion acerca de nuestros productos"
      />
      <div className="my-brackground">
        <SiteStructure>
          <div>
            <h1 className="text-white d-flex flex-row-reverse">CONTACTENOS</h1>
            <div className="divider"></div>
            <div className="pt-2 pt-lg-4 d-flex">
              <Map />

              <div className="text-white pl-3">
                <a
                  className="facebook-icon pb-5 d-flex"
                  href="https://www.facebook.com/blueskysaMza"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconContext.Provider
                    value={{
                      color: "#FFFFFF",
                      className: "global-class-name",
                      size: 48,
                    }}
                  >
                    <FaFacebook />
                  </IconContext.Provider>
                  <h2 className="text-white d-flex  pl-2">BlueSkysaMza</h2>
                </a>
                {/* <a
                  className="whatsapp-icon pb-5 d-flex"
                  href="https://wa.me/+5492614158538"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconContext.Provider
                    value={{
                      color: "#FFFFFF",
                      className: "global-class-name",
                      size: 48,
                    }}
                  >
                    <FaWhatsapp />
                  </IconContext.Provider>
                  <h2 className="text-white d-flex  pl-2">54 261 4158538</h2>
                </a> */}
                <a
                  className="whatsapp-icon pb-5 d-flex"
                  href="https://wa.me/+5492614543598"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconContext.Provider
                    value={{
                      color: "#FFFFFF",
                      className: "global-class-name",
                      size: 48,
                    }}
                  >
                    <FaWhatsapp />
                  </IconContext.Provider>
                  <h2 className="text-white d-flex  pl-2">54 261 4543598</h2>
                </a>
                <a
                  className="email-icon pb-5 d-flex"
                  href="mailto:info@blueskysa.com.ar"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconContext.Provider
                    value={{
                      color: "#FFFFFF",
                      className: "global-class-name",
                      size: 48,
                    }}
                  >
                    <GrMail />
                  </IconContext.Provider>
                  <h2 className="text-white d-flex pl-2">
                    info@blueskysa.com.ar
                  </h2>
                </a>
                <a
                  className="instagram-icon pb-5 d-flex"
                  href="https://www.instagram.com/bluesky.s.a/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconContext.Provider
                    value={{
                      color: "#FFFFFF",
                      size: 48,
                    }}
                  >
                    <FaInstagram />
                  </IconContext.Provider>
                  <h2 className="text-white d-flex  pl-2">BLUESKY.S.A</h2>
                </a>
                <p
                  className="instagram-icon pb-5 d-flex"
                  href="https://www.instagram.com/bluesky.s.a/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconContext.Provider
                    value={{
                      color: "#FFFFFF",
                      size: 48,
                    }}
                  >
                    <FaMapMarkerAlt />
                  </IconContext.Provider>
                  <h3 className="text-white d-flex  pl-2">
                    Agustin Alvarez 665, Mendoza
                  </h3>
                </p>
              </div>
            </div>
          </div>
        </SiteStructure>
      </div>
    </>
  );
}
